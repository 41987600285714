import battery from '../assets/pack/android-l-battery.png';
import clothes from '../assets/pack/clothes.png';
import covid from '../assets/pack/coronavirus.png';
import suit from '../assets/pack/suit.png';
import license from '../assets/pack/driver-license-card.png';
import earbud from '../assets/pack/earbud-headphones.png';
import electric from '../assets/pack/electrical.png';
import flipflolp from '../assets/pack/flip-flops--v1.png';
import laptop from '../assets/pack/laptop.png';
import visa from '../assets/pack/passport-and-tickets.png';
import passport from '../assets/pack/passport.png';
import meds from '../assets/pack/pills.png';
import shoes from '../assets/pack/shoes.png';
import sunscreen from '../assets/pack/sun-glasses.png';
import swimwear from '../assets/pack/swimming-cap.png';
import whatsapp from '../assets/pack/whatsapp.png';

import React from "react";

const PACKING_LIST = [
    {label: 'Click to join the Whatsapp Group', icon: whatsapp, link: 'https://chat.whatsapp.com/KcrvWxUrdicBQBEy5SlCD7', outlineColor: '#00b120'},
    {label: 'Passport', icon: passport},
    {label: 'Travel Visa (If applicable)', icon: visa},
    // {label: 'Proof of vaccination', icon: covid},
    {label: 'Laptop & Charger (cables/adapters)', icon: laptop},
    {label: 'Mobile Phone charger', icon: battery},
    {label: 'Power converter (to European) / Adapter', icon: electric},
    {label: 'Headphones', icon: earbud},
    {label: 'Evening events - Cocktails wear', icon: suit},
    {label: 'Essentials clothing', icon: clothes},
    {label: 'Swimwear', icon: swimwear},
    {label: 'Flip flops', icon: flipflolp},
    {label: 'Sunscreen', icon: sunscreen},
    {label: 'Medications', icon: meds},
    {label: 'Driver License', icon: license},
    {label: 'Comfortable shoes', icon: shoes}
]

class Packing extends React.Component {
    render() {
        return <div id="packing">
            <h1>Packing List</h1>
            <div id="packing-list">
                {PACKING_LIST.map(item =>
                    item.link ? <a target="_blank" href={item.link}>
                    <div style={{outline: `3px solid ${item.outlineColor || 'white'}`}} className="packing-list-item">
                        <img style={{'width': '32px', 'placeSelf': 'center'}} src={item.icon} />
                        <span>{item.label}</span>
                    </div>
                </a> :
                <div style={{outline: `3px solid ${item.outlineColor || 'white'}`}} className="packing-list-item">
                    <img style={{'width': '32px', 'placeSelf': 'center'}} src={item.icon} />
                    <span>{item.label}</span>
                </div>
                )}
            </div>
        </div>;
    }
}

export default Packing;
