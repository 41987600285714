import React from "react";
import locationImg from "../assets/map-pin.svg";
import timeImg from "../assets/clock.svg";
import infoImg from "../assets/info.svg";
import shirtImg from "../assets/shirt.svg";

const LEGEND = {
    "HOTEL": {
        "name": "Hotel",
        "color": "#f03e3e"
    },
    "FOOD": {
        "name": "Food",
        "color": "#1c7ed6"
    },
    "ACTIVITY": {
        "name": "Activity",
        "color": "#37b24d"
    },
    // "TRANSPORT": {
    //     "name": "Transport",
    //     "color": "#f59f00"
    // },
    "OTHER": {
        "name": "Other",
        "color": "#ae3ec9"
    }
};

const AGENDA = [
    // Day 1
    {
        "label": "Day 1",
        "date": "June 3rd",
        "items": [
            {
                "label": "Arrivals & Check-in during the day",
                "time": "8:00 AM - 3:00 PM",
                "location": "",
                "category": "HOTEL",
                "description": ""
            },
            {
                "label": "Lunch at the hotel restaurant",
                "time": "1:00 PM - 3:00 PM",
                "location": "Hotel's Restaurant",
                "category": "FOOD",
                "description": "Optional"
            },
            {
                "label": "Leisure time",
                "time": "3:00 PM - 7:00 PM",
                "location": "",
                "category": "OTHER",
                "description": ""
            },
            {
                "label": "Welcome Cocktails & Dinner",
                "time": "7:00 PM - 10:00 PM",
                "location": "Hotel",
                "category": "FOOD",
                "attire": "Casual Attire",
                "description": "Mandatory"
            }
        ]
    },
    // Day 2
    {
        "label": "Day 2",
        "date": "June 4th",
        "items": [
            {
                "label": "Breakfast",
                "time": "7:00 AM - 10:00 AM",
                "location": "Hotel's Restaurant",
                "category": "FOOD",
                "description": "Optional"
            },
            {
                "label": "Winery inc. wine tasting and picnic",
                "time": "10:00 AM - 3:00 PM",
                "location": "",
                "category": "ACTIVITY",
                "description": "Optional but recommended!"
            },
            {
                "label": "Leisure Time",
                "time": "3:00 PM - 7:00 PM",
                "location": "",
                "category": "OTHER",
                "description": ""
            },
            {
                "label": "Dinner at the hotel restaurant",
                "time": "7:00 PM - 9:00 PM",
                "location": "Hotel's Restaurant",
                "category": "FOOD",
                "description": "Optional"
            }
        ]
    },
    // Day 3
    {
        "label": "Day 3",
        "date": "June 5th",
        "items": [
            {
                "label": "Breakfast",
                "time": "7:00 AM - 10:00 AM",
                "location": "Hotel's Restaurant",
                "category": "FOOD",
                "description": "Optional"
            },
            {
                "label": "Three exciting activity options (including lunch)",
                "time": "10:00 AM - 2:30 PM",
                "location": "",
                "category": "ACTIVITY",
                "description": "Follow the 'to bring' items in the activities page!"
            },
            {
                "label": "Leisure Time",
                "time": "2:30 PM - 7:00 PM",
                "location": "",
                "category": "OTHER",
                "description": ""
            },
            {
                "label": "Gala Dinner Event",
                "time": "7:00 PM - 11:00 PM",
                "location": "",
                "category": "FOOD",
                "description": "Mandatory",
                "attire": "Put on your best casual elegant, Italian style!"
            }
        ]
    },
    // Day 4
    {
        "label": "Day 4",
        "date": "June 6th",
        "items": [
            {
                "label": "Breakfast",
                "time": "7:00 AM - 10:00 AM",
                "location": "Hotel's Restaurant",
                "category": "FOOD",
                "description": "Optional"
            },
            {
                "label": "Check Out and Leisure time",
                "time": "11:00 AM",
                "location": "",
                "category": "HOTEL",
                "description": ""
            },
            {
                "label": "Lunch at the hotel restaurant",
                "time": "1:00 PM - 3:00 PM",
                "location": "Hotel's Restaurant",
                "category": "FOOD",
                "description": "Optional"
            }
        ]
    }
];

class Agenda extends React.Component {
    render() {
        return <div id="agenda">
            <h1>Agenda</h1>

            <div className="content">
                {/*<div>*/}
                {/*    The agenda is coming soon..*/}
                {/*</div>*/}
                {/*<a id="calendar-fab" className="t-right t-auto" data-tooltip="Google Calendar Events Follow Up" href="" target="_blank">*/}
                {/*    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/2048px-Google_Calendar_icon_%282020%29.svg.png" alt="Agenda" />*/}
                {/*</a>*/}

                <div id="legend">
                    {Object.keys(LEGEND).map((key, index) => <div key={index} className="legend-item">
                        <div className="legend-item-circle" style={{backgroundColor: LEGEND[key].color}}></div>
                        <div className="agenda-legend-item-label">{LEGEND[key].name}</div>
                    </div>)}
                </div>

                <div className="agenda-items">
                    {AGENDA.map((day    , index) => <div key={index} className="agenda-day">
                        <div className="agenda-day-label">{day.label}</div>
                        <div className="agenda-day-date">{day.date}</div>
                        <div className="agenda-day-items">
                            {day.items.map((item, index) => <div key={index} className="agenda-item">
                                <div className="agenda-item-legend" style={{backgroundColor: LEGEND?.[item?.category]?.color}}> </div>
                                <div className="agenda-item-label">{item.label}</div>
                                <div hidden={!item.time} className="agenda-item-time">
                                    <img src={timeImg} />
                                    <span>{item.time}</span>
                                </div>
                                <div hidden={!item.location} className="agenda-item-location">
                                    <img src={locationImg} />
                                    <span>{item.location}</span>
                                </div>
                                <div hidden={!item.attire} className="agenda-item-attire">
                                    <img src={shirtImg} style={{"width": "18px", "marginRight": "5px"}}/>
                                    <span>{item.attire}</span>
                                </div>
                                <div hidden={!item.description} className="agenda-item-description">
                                    <img src={infoImg} />
                                    <span>{item.description}</span>
                                </div>
                            </div>)}
                        </div>
                    </div>)}
                </div>
            </div>
        </div>;
    }
}

export default Agenda;
