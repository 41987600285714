import React from "react";
import {Parallax, Background} from "react-parallax";

class Destination extends React.Component {
    render() {
        return <div id="destination">
            <Parallax
                bgImage="https://www.tapcorporate.com/-/media/Flytap/new-tap-pages/destinations/europe/italy/rome/destinations-rome-banner-desktop-v2-1920x600.jpg?h=600&w=1920&la=en&hash=1FF36C07C5CEFB243F74008BEBFE902510AAB3E0"
                strength={5}>
                <div style={{height: 500}}>
                    <div className="insideStyle">
                        <h1>Destination</h1>
                        <div>Welcome to Rome, the ultimate time-travel destination where history high-fives the present!
                            Picture this: Julius Caesar sipping espresso at a sidewalk café. Rome's past isn't just a history lesson; it's a blockbuster movie starring the Eternal City itself! Fast forward to today, where Rome is not just an ancient relic but a living, breathing masterpiece.
                        </div>
                        <div>In June, the average temperature in Rome is anywhere between 62°F (17°C) to 82°F (28°C).</div>
                        <div>Let the adventures begin! 🎉🍕🏛️</div>
                    </div>
                </div>
            </Parallax>
            <div className="content">
                <h2>Hotel</h2>
                <div style={
                    {
                        "display": "flex",
                        "gap": "10px",
                        "justify-content": "space-between",
                    }}
                >

                    <div className="content-inner">
                        <div>
                            <div>W Hotel, Rome</div>
                        </div>

                        <div>WELCOME TO W ROME! The W Rome hotel is located in a historic palazzo in Rome. It emphasizes
                            the blend of modern luxury with the energy of the Eternal City, conveniently situated near
                            landmarks like the Trevi Fountain and Spanish Steps. In 2023, the hotel received several
                            awards, including being listed in CN Traveler's Hot List and Travel + Leisure's It List, as
                            well as winning the CN Readers' Choice for Best Hotels in Europe and the Best Hotel
                            Gastronomic Experience at the Food & Wine Awards.
                        </div>
                        <br />
                        <div><a target="_blank"
                                href="https://virtualinterior.it/w-rome/">Link
                            To Hotel</a></div>
                        <div><a target="_blank"
                                href="https://maps.app.goo.gl/pb5pSWfic9hfnFF6A?g_st=iw">Our Restaurant Recommendations</a></div>
                        <br/>
                    </div>
                    <img
                        style={{height: '400px', "border-radius": "10px"}}
                        src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/2b/03/b5/25/exterior.jpg"
                    />
                </div>

            </div>
        </div>;
    }
}

export default Destination;
