import React from "react";
import {Input, DatePicker, Checkbox, Radio, message, Spin, TimePicker, Select} from 'antd';

import {Prompt} from 'react-router'
import moment from 'moment';


import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const FORM_FIELDS = [
    {
        type: "title",
        label: "Employee Details"
    },
    {
        'id': 'employeeFirstName',
        "label": "Employee First name",
        "type": "text",
        "placeholder": "Harry"
    },
    {
        'id': 'employeeLastName',
        "label": "Employee Last name",
        "type": "text",
        "placeholder": "Potter"
    },
    {
        'id': 'employeePhoneNumber',
        "label": "Employees Mobile Phone (including country code)",
        "placeholder": "+446244262442",
        "type": "text",
    },
    {
        'id': 'employeeDietaryRequirements',
        "label": "Do you have any dietary Requirements?",
        "type": "checkbox",
        "options": [
            {
                "label": "none",
                "value": "none"
            },
            {
                "label": "gluten free",
                "value": "gluten"
            },
            {
                "label": "vegan",
                "value": "vegan"
            },
            {
                "label": "vegetarian",
                "value": "vegetarian"
            },
            {
                "label": "halal",
                "value": "halal"
            },
            {
                "label": "kosher",
                "value": "kosher"
            },
            {
                "label": "other/allergy",
                "value": "other"
            }
        ]
    },
    {
        'id': 'employeeDietryRequirementsOther',
        "label": "Please specify",
        "type": "text",
        showIf: [
            {id: 'employeeDietaryRequirements', value: 'other'}
        ]
    },
    {
        'id': 'employeeAccessibilityNeeds',
        "label": "Do you have any accessibility needs?",
        "type": "radio",
        "options": [
            {
                "label": "Yes",
                "value": "yes"
            },
            {
                "label": "No",
                "value": "no"
            },
        ],
    },
    {
        'id': 'employeeAccessibilityNeedsDescription',
        "label": "Briefly describe your needs",
        "type": "text",
        showIf: [
            {id: 'employeeAccessibilityNeeds', value: 'yes'}
        ]
    },
    {
        'id': "employeeGoesToWinery",
        "label": "Employee Group Activity",
        "type": "radio",
        "options": [
            {
                "label": "Winery Tour",
                "value": "winerytour"
            },
            {
                "label": "Free Time",
                "value": "free"
            }
        ]
    },
    {
        'id': 'employeeActivity',
        "label": "Employee Preferred Activity",
        "type": "radio",
        "options": [
            {
                "label": "Vespa Tour",
                "value": "vespa",
                "limit": 38
            },
            {
                "label": "Italian Cooking Class",
                "value": "cooking",
                "limit": 28
            },
            {
                "label": "Polaroid Tour",
                "value": "polaroid",
                "limit": 1
            },
            {
                "label": "Free Time",
                "value": "free",
                "limit": 200
            }
        ],
    },
    {
        'id': 'employeeBedPreference',
        "label": "Bed Preference",
        "type": "radio",
        "options": [
            {
                "label": "Two Single Beds",
                "value": "king"
            },
            {
                "label": "One Double Bed",
                "value": "queen"
            }
        ]
    },
    {
        type: "title",
        label: "Employee Passport Details"
    },
    {
        id: 'employeePassportFirstName',
        label: "Employee Passport First Name",
        type: "text",
        placeholder: "Harry"
    },
    {
        id: 'employeePassportMiddleName',
        label: "Employee Passport Middle Name",
        type: "text",
        optional: true,
        placeholder: "James"
    },
    {
        id: 'employeePassportLastName',
        label: "Employee Passport Last Name",
        type: "text",
        placeholder: "Potter"
    },
    {
        id: 'employeePassportGender',
        label: "Employee Passport Gender",
        type: "radio",
        "options": [
            {
                "label": "Male",
                "value": "male"
            },
            {
                "label": "Female",
                "value": "female"
            },
            {
                "label": "Other",
                "value": "other"
            }
        ],
    },
    {
        id: 'employeePassportNumber',
        label: "Employee Passport Number",
        type: "text",
        placeholder: "123456789"
    },
    {
        id: 'employeePassportIssueDate',
        label: "Employee Passport Issue Date",
        type: "date",
        placeholder: "01 January 2019"
    },
    {
        id: 'employeePassportIssueCountry',
        label: "Employee Passport Place of Issue",
        type: "text",
        placeholder: "United Kingdom"
    },
    {
        id: 'employeePassportExpiryDate',
        label: "Employee Passport Expiry Date",
        type: "date",
        placeholder: "01 January 2029"
    },
    {
        id: 'employeePassportNationality',
        label: "Employee Passport Nationality",
        type: "text",
        placeholder: "British"
    },
    {
        id: 'employeePassportDateOfBirth',
        label: "Employee Date of Birth",
        type: "date",
        placeholder: "31 July 1980"
    },
    {
        id: "hasGuest",
        label: "Are you bringing a guest?",
        type: "radio",
        options: [
            {
                "label": "Yes",
                "value": "yes"
            },
            {
                "label": "No",
                "value": "no"
            }
        ]
    },
    {
        type: "title",
        label: "Guest Details",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        'id': 'guestFirstName',
        "label": "Guest First name",
        "type": "text",
        "placeholder": "Ronald",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        'id': 'guestLastName',
        "label": "Guest Last name",
        "type": "text",
        "placeholder": "Weasley",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        'id': 'guestPhoneNumber',
        "label": "Guest Mobile Phone (including country code)",
        "placeholder": "+446244262442",
        "type": "text",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        'id': 'guestDietaryRequirements',
        "label": "Do you have any dietary Requirements?",
        "type": "checkbox",
        "options": [
            {
                "label": "none",
                "value": "none"
            },
            {
                "label": "gluten free",
                "value": "gluten"
            },
            {
                "label": "vegan",
                "value": "vegan"
            },
            {
                "label": "vegetarian",
                "value": "vegetarian"
            },
            {
                "label": "halal",
                "value": "halal"
            },
            {
                "label": "kosher",
                "value": "kosher"
            },
            {
                "label": "other/allergy",
                "value": "other"
            }
        ],
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        'id': 'guestDietryRequirementsOther',
        "label": "Please specify",
        "type": "text",
        showIf: [
            {id: 'guestDietaryRequirements', value: 'other'},
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        'id': 'guestAccessibilityNeeds',
        "label": "Does your guest have any accessibility needs?",
        "type": "radio",
        "options": [
            {
                "label": "Yes",
                "value": "yes"
            },
            {
                "label": "No",
                "value": "no"
            },
        ],
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        'id': 'guestAccessibilityNeedsDescription',
        "label": "Briefly describe your needs",
        "type": "text",
        showIf: [
            {id: 'guestAccessibilityNeeds', value: 'yes'},
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        'id': "guestGoesToWinery",
        "label": "Guest Group Activity",
        "type": "radio",
        "options": [
            {
                "label": "Winery Tour",
                "value": "winerytour"
            },
            {
                "label": "Free Time",
                "value": "free"
            }
        ],
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        'id': 'guestActivity',
        "label": "Guest Preferred Activity",
        "type": "radio",
        "options": [
            {
                "label": "Vespa Tour",
                "value": "vespa",
                "limit": 38
            },
            {
                "label": "Italian Cooking Class",
                "value": "cooking",
                "limit": 28
            },
            {
                "label": "Polaroid Tour",
                "value": "polaroid",
                "limit": 1
            },
            {
                "label": "Free Time",
                "value": "free",
                "limit": 200
            }
        ],
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        type: "title",
        label: "Guest Passport Details",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportFirstName',
        label: "Guest Passport First Name",
        type: "text",
        placeholder: "Ron",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportMiddleName',
        label: "Guest Passport Middle Name",
        type: "text",
        optional: true,
        placeholder: "Bill",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportLastName',
        label: "Guest Passport Last Name",
        type: "text",
        placeholder: "Weasley",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportGender',
        label: "Guest Passport Gender",
        type: "radio",
        "options": [
            {
                "label": "Male",
                "value": "male"
            },
            {
                "label": "Female",
                "value": "female"
            },
            {
                "label": "Other",
                "value": "other"
            }
        ],
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportNumber',
        label: "Guest Passport Number",
        type: "text",
        placeholder: "123456789",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportIssueDate',
        label: "Guest Passport Issue Date",
        type: "date",
        placeholder: "01 January 2019",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportIssueCountry',
        label: "Guest Passport Place of Issue",
        type: "text",
        placeholder: "United Kingdom",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportExpiryDate',
        label: "Guest Passport Expiry Date",
        type: "date",
        placeholder: "01 January 2029",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportNationality',
        label: "Guest Passport Nationality",
        type: "text",
        placeholder: "British",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
    {
        id: 'guestPassportDateOfBirth',
        label: "Guest Date of Birth",
        type: "date",
        placeholder: "31 July 1980",
        showIf: [
            {id: 'hasGuest', value: 'yes'}
        ]
    },
];

class Register extends React.Component {

    constructor() {
        super();
        this.state = {
            data: {},
            limits: {
            },
            alert: true,
            loading: true
        }
    }

    async componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        // Enable navigation prompt
        window.onbeforeunload = () => true

        await this.fetchData();
        await this.fetchLimits();
        await this.setState({loading: false});
        setInterval(this.fetchLimits.bind(this), 6000);
    }

    async fetchLimits() {
        const limits = await this.readLimitsFromFirestore();
        await this.setState({
            limits
        });
    }

    async fetchData() {
        const data = await this.readFromFirestore();
        await this.setState({
            data,
        });
    }

    isFieldRequiredByShowIf(field) {
        const {showIf} = field;
        if (showIf && showIf.length > 0) {
            const showIfConditions = showIf.map(condition => {
                if (typeof this.state.data[condition.id] === 'object') {
                    return this.state.data[condition.id].includes(condition.value);
                }

                return this.state.data[condition.id] === condition.value;
            });

            if (!showIfConditions.every(condition => condition)) {
                return true;
            }
        }
        return false;
    }

    createField(field, index, disabled) {
        if (this.isFieldRequiredByShowIf(field)) {
            return ''
        }

        switch (field.type) {
            case "text":
                return <div className='text-input-wrapper' key={index}>
                    <label>{field.label}{!field.optional ? <span required>*</span> : ''}</label>
                    <Input disabled={disabled} onChange={async ({target: {value}}) => await this.setState({
                        data: {
                            ...this.state.data,
                            [field.id]: value
                        }
                    })} type="text" placeholder={field.placeholder}
                           value={this.state.data?.[field.id] || field.value || ''}/>
                </div>;
            case "amountWithCurrency":
                return <div className='amount-wrapper-with-label' key={index}>
                    <label>{field.label}{!field.optional ? <span required>*</span> : ''}</label>
                    <div className='amount-wrapper'>
                        <Select
                            disabled={disabled}
                            onChange={async (value) => await this.setState({
                                data: {
                                    ...this.state.data,
                                    [field.id + 'Currency']: value
                                }
                            })}
                            value={this.state.data?.[field.id + 'Currency']}
                            style={{width: 120}}
                            options={field.options}
                        />
                        <Input disabled={disabled} onChange={async ({target: {value}}) => await this.setState({
                            data: {
                                ...this.state.data,
                                [field.id]: value
                            }
                        })} type="number" placeholder={field.placeholder} value={this.state.data?.[field.id]}/>
                    </div>
                </div>;
            case "date":
                return <div className='date-input-wrapper' key={index}>
                    <label>{field.label}{!field.optional ? <span required>*</span> : ''}</label>
                    <div>
                        {/*<DatePicker value={this.state.data?.[field.id]?.seconds * 1000} onChange={async ({_d: value}) => await this.setState({data: {...this.state.data, [field.id]: value}})} />*/}
                        <DatePicker
                            disabled={disabled}
                            format="DD MMMM, yyyy"
                            placeholder={field.placeholder || 'Enter date'}
                            defaultValue={this.state.data?.[field.id]?.seconds ? moment(new Date(this.state.data?.[field.id]?.seconds * 1000), 'YYYY-MM-DD') : ''}
                            onChange={async ({_d: value}) => {
                                await this.setState({
                                    data: {
                                        ...this.state.data,
                                        [field.id]: value
                                    }
                                })
                            }}/>
                    </div>
                </div>;
            case "time":
                return <div className='time-input-wrapper' key={index}>
                    <label>{field.label}{!field.optional ? <span required>*</span> : ''}</label>
                    <div>
                        <TimePicker
                            disabled={disabled}
                            format="HH:mm"
                            placeholder={field.placeholder || 'Enter time'}
                            defaultValue={this.state.data?.[field.id]?.seconds ? moment(new Date(this.state.data?.[field.id]?.seconds * 1000), 'HH:mm') : ''}
                            showNow={false} onChange={async ({_d: value}) => await this.setState({
                            data: {
                                ...this.state.data,
                                [field.id]: value
                            }
                        })}/>
                    </div>
                </div>;
            case "radio":
                return <div><Radio.Group disabled={disabled} value={this.state.data?.[field.id]}
                                         onChange={async ({target: {value}}) => await this.setState({
                                             data: {
                                                 ...this.state.data,
                                                 [field.id]: value
                                             }
                                         })} className='radio-input-wrapper' key={index}>
                    <label>{field.label}{!field.optional ? <span required>*</span> : ''}</label>
                    <div className='radio-input-wrapper-inner'>
                        {field.options.map((option, index) => {
                            return <div key={index}>
                                <Radio.Button disabled={(this.state.limits?.[option.value] >= option.limit) || disabled}
                                              id={option.value} name={field.id}
                                              value={option.value}>{option.label}</Radio.Button>
                                <div>hi!!!!! {this.state.limits?.[option.value]}, {option.limit}</div>
                            </div>
                        })}
                    </div>
                </Radio.Group></div>;
            case "checkbox":
                return <div className='checkbox-input-wrapper' key={index}>
                    <label>{field.label}{!field.optional ? <span required>*</span> : ''}</label>
                    <div className='checkbox-input-wrapper-inner'>
                        <Checkbox.Group value={this.state.data[field.id]}
                                        disabled={disabled}
                                        onChange={async (value) => await this.setState({
                                            data: {
                                                ...this.state.data,
                                                [field.id]: value
                                            }
                                        })} options={field.options}/>
                    </div>
                </div>;
            case "title":
                return <h2 key={index}>{field.label}</h2>;
            case "paragraph":
                return <p style={{'color': field.color || 'black'}} key={index}>{field.label}</p>;
            default:
                return <div key={index}>oops! field not found.</div>;

        }
    }

    async getLoggedInUser() {
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    resolve(user);
                } else {
                    reject();
                }
            });
        });
    }

    async postToFirestore(obj) {
        const {uid, email} = await this.getLoggedInUser();
        const ref = firebase.firestore().collection('users').doc(uid);
        ref.set(obj);
        await this.setState({
            data: {
                ...this.state.data,
                email
            }
        })
    }

    async readFromFirestore() {
        const {uid} = await this.getLoggedInUser();
        const ref = firebase.firestore().collection('users').doc(uid);
        return await ref.get().then(doc => {
            if (doc.exists) {
                return doc.data();
            } else {
                // doc.data() will be undefined in this case
                return {}
            }
        }).catch(function (error) {
            return {}
        });
    }

    async readLimitsFromFirestore() {
        const ref = firebase.firestore().collection('shared').doc('shared');
        return await ref.get().then(doc => {
            if (doc.exists) {
                return doc.data();
            } else {
                // doc.data() will be undefined in this case
                return {}
            }
        }).catch(function (error) {
            return {}
        });
    }

    createForm(disabled = false) {
        return <form id="register-form">
            {FORM_FIELDS.map((field, index) => {
                return this.createField(field, index, disabled);
            })}
        </form>;

    }


    async send() {
        // const expectedFields = FORM_FIELDS.filter(field => !!field.id && !field.optional && !this.isFieldRequiredByShowIf(field));
        // const unfilledFields = expectedFields.filter(field => !this.state.data[field.id]);
        //
        // if (unfilledFields.length > 0) {
        //     return message.error(`Please fill in the following fields: ${unfilledFields.map(i => i.id).join(', ')}`);
        // }

        await this.postToFirestore(this.state.data);
        await this.setState({
            alert: false,
        })
        window.onbeforeunload = undefined
        window.location.hash = "#success"
    }


    render() {
        return <div id="register-page">
            <Prompt
                when={this.state.alert}
                message='You have unsaved changes, are you sure you want to leave?'
            />
            <h1>Registration Form</h1>
            <p>Once you submit the form, you can edit it by going to this page again</p>
            <p>This form is for employees. Guests will be able to register after this form is complete</p>
            <p
                style={
                    {
                        color: "red",
                        fontSize: "20px",
                        fontWeight: "bold"
                }}>
                Registration deadline is April 17th
            </p>
            <div className="content">
                {this.state.loading ? <div className="loading">
                    <Spin/>
                </div> : this.createForm(false)}
                <button id="submit-button" onClick={this.send.bind(this)}>Submit</button>
            </div>
        </div>;
    }
}

export default Register;
