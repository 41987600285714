import React from "react";

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Confetti from 'react-confetti'

class Success extends React.Component {

    constructor() {
        super();
        this.state = {
            data: {},
        }
    }

    async componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const data = await this.readFromFirestore();
        await this.setState({
            data
        });
    }

    async getLoggedInUser() {
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    resolve(user);
                } else {
                    reject();
                }
            });
        });
    }

    async readFromFirestore() {
        const {uid} = await this.getLoggedInUser();
        const ref = firebase.firestore().collection('users').doc(uid);
        return await ref.get().then(doc => {
            if (doc.exists) {
                console.log(doc.data())
                return doc.data();
            } else {
                // doc.data() will be undefined in this case
                return {}
            }
        }).catch(function (error) {
            return {}
        });
    }

    camelCaseToSentenceCase(text){
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    getdecimal(num) {
        return num.toString().split('.')[1];
    }

    render() {
        const {data} = this.state;
        const width = window.innerWidth;
        const height = window.innerHeight;

        return <div id="success-page">
            <Confetti
                recycle={false}
                tweenDuration={1}
                friction={1.01}
            />
            <h1>Thanks for Registering!</h1>
            <h2>Dear {data?.employeeFirstName || 'employee'},</h2>
            <p>Below is a quick summary of your registration. Please review and let us know if you have any questions.</p>

            <p>If you have any questions, please reach out to <a href={'mailto:or.oshrov@forter.com'}>susy.ingrao@forter.com</a></p>

            <table>
                <tbody>
                <tr>
                    <td>Hotel Name</td>
                    <td>W Hotel, Rome</td>
                </tr>

                </tbody>
            </table>

            <h2>Registrant</h2>
            <table>
                <tbody>
                {Object.entries(data).map((item, index) => <tr>
                        <td>{this.camelCaseToSentenceCase(item[0])}</td>
                        <td>{item[1]?.seconds ?
                            new Intl.DateTimeFormat("en-US", {year: "numeric", month: "long", day: "2-digit" }).format(new Date(item[1]?.seconds * 1000)) : item[1]}</td>
                    </tr>)}
                </tbody>
            </table>

        </div>;
    }
}

export default Success;
