import gearImg from '../assets/settings.svg';
import pinImg from '../assets/map-pin.svg';
import timeImg from '../assets/clock.svg';
import bringImg from '../assets/shopping-bag.svg';
import infoImg from '../assets/info.svg';
import alertImg from '../assets/alert-triangle.svg';
import shirtImg from '../assets/shirt.svg';

import React from "react";
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const info = <img src={infoImg}/>
const gear = <img src={gearImg}/>
const pin = <img src={pinImg}/>
const time = <img src={timeImg}/>
const bring = <img src={bringImg}/>
const alert = <img src={alertImg}/>
const shirt = <img src={shirtImg}/>

const insideStyles = {
    background: "white",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
};

class Activities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs1: [
                {
                    name: 'Vespa Tour',
                    img: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/06/6f/55/c4.jpg',
                    text: <div>
                        <div>{info} Experience Rome like the locals do, riding on the back of a classic Vespa and reminiscing about the film Roman Holiday with Gregory Peck and Audrey Hepburn. Each participant will be accompanied by an expert driver (both male and female) on a journey through the centuries of Roman history, offering an unusual perspective of the city. You'll dance around the city, stopping at various iconic locations such as the Colosseum, Vatican City, a special viewpoint, and taking breaks for coffee and a tour at the Campo de' Fiori market.
                        </div>
                        {/*<div>{time} 2.5-3 hours </div>*/}
                        <div>{bring} Bring: Closed & comfortable shoes and sunglasses</div>
                        <div>{alert} This is a fun activity with no stress, as you won’t have to drive. You'll get to see all the sights from a Roman perspective in a short amount of time. </div>
                    </div>
                },
                {
                    name: 'Vintage Photo Tour With a Polaroid Camera',
                    img: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/06/e3/a0/2f.jpg',
                    text: <div>
                        <div>{info} Step back in time and capture the essence of Rome with our Vintage Photo Tour. With the vintage camera and a roll of instant film at your disposal, our knowledgeable guide will lead you through picturesque streets, offering photography tips and intriguing insights into the rich history and culture of the area. Before setting off, indulge in an informative introduction where you'll discover the fascinating history of instant photography and learn the basics of operating your vintage camera and film. No prior experience with Polaroid or photography is necessary – just bring your enthusiasm and curiosity for this unique photographic adventure.</div>
                        {/*<div>{time} 4 hours (Including Lunch)</div>*/}
                        <div>{bring} Bring: Comfortable shoes and a Hat </div>
                        {/*<div>{alert} For safety reasons, pregnant women and people with back, neck, heart or dizziness conditions restricted</div>*/}
                    </div>
                },
                {
                    name: 'Chef Academy Cooking Course',
                    img: 'https://image.cookly.me/tr:h-504,w-764,pr-true,rt-auto/images/become-a-roman-masterchef-pasta-ravioli-and-tiramisu-class_NY7AlMf.png',
                    text: <div>
                        <div>{info} Explore Rome and Italian gastronomy with our immersive Chef Academy Cooking Course. Learn the art of pasta making and traditional dishes in a captivating setting. Roll dough, craft two types of fresh pasta, and enjoy a delicious lunch with wine. Work together to perfect your pasta skills. Join us for a memorable culinary experience. </div>
                        {/*<div>{time} 4 hours (Including Lunch)</div>*/}
                        <div>{bring} Bring: Closed & comfortable shoes </div>
                        {/*<div>{pin} Athens City Center</div>*/}
                    </div>
                },
            ],
            tabs2: [
                {
                    name: 'Wine Tasting',
                    img: 'https://i.imgur.com/OVTF1Mg.png',
                    text: <div>
                        <div>{info} Enjoy panoramic views and aromatic delights during our terrace wine tasting. Explore our winemaking legacy through guided tours of ancient cellars, steeped in centuries of tradition. Relax in the vineyard's beauty with a curated picnic, pairing local treats with our finest wines.</div>
                        {/*<div>{time} 6 hours</div>*/}
                        {/*<div>On-board experience: Lunch, Open Bar</div>*/}
                        {/*<div>{gear} Gear Provided: Towels & Snorkels</div>*/}
                    </div>
                }
            ],
            // tabs3: [
            //     {
            //         name: 'Adventure ATV Tour',
            //         img: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/06/74/84/eb.jpg',
            //         text: <div>
            //             <div>{info} Go out and enjoy the most complete excursion in Punta Cana. In this ATV tour, you'll
            //                 visit small villages in the Dominican countryside, Domitai Cave Park, and an organic co-op
            //                 farm where you'll learn about coconut oil, tobacco, rum, coffee, chocolate, and more.
            //             </div>
            //             <div>{time} Approximately 5 hours</div>
            //         </div>
            //     },
            //     {
            //         name: 'DR Fish Ocean Spa',
            //         img: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/06/71/19/b1.jpg',
            //         text: <div>
            //             <div>{info} Pamper your body with an Ocean Spa in Punta Cana!
            //                 Absorb the marine environment while you enjoy a delicate massage and total relaxation on a
            //                 floating mattress in the sea and stretch your body doing yoga and Bio Pilates.
            //             </div>
            //             <div>{time} Approximately 5 hours</div>
            //         </div>
            //     },
            //     {
            //         name: 'Plantation Experience Tour',
            //         img: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/06/74/b5/35.jpg/07/a2/79/66.jpg',
            //         text: <div>
            //             <div>{info} This tour starts with a visit to the typical Dominican house and a walk inside a
            //                 plantation full of different plants, where you'll get the chance to see and taste the whole
            //                 roasting process of coffee and the local drink Mamajuana.
            //             </div>
            //             <div>{time} Approximately 5 hours</div>
            //         </div>
            //     },
            // ],
            // tabs4: [
            //     {
            //         name: 'Coco Bongo',
            //         img: 'https://cdn1.wellet.fun/11085636-afd9-4f88-ae85-bf8dfa1189dc',
            //         text: <div>
            //             <div>{info} An amazing nightclub that combines a dance party with an unforgettable show.</div>
            //             <div>{alert} If you are interested in booking an activity or need any assistance, you can
            //                 contact the production company throughout the event and we’ll be happy to help or do it for
            //                 you!
            //             </div>
            //         </div>
            //     },
            //     {
            //         name: 'El Patio',
            //         img: 'https://media-cdn.tripadvisor.com/media/photo-s/10/54/1e/5d/el-patio-restaurant--v16640539.jpg',
            //         text: <div>
            //             <div>{info} A bar and restaurant in the open air with Dominican live music.</div>
            //             <div>{alert} If you are interested in booking an activity or need any assistance, you can
            //                 contact the production company throughout the event and we’ll be happy to help or do it for
            //                 you!
            //             </div>
            //         </div>
            //     },
            //     {
            //         name: 'Hard Rock Café',
            //         img: 'https://media-cdn.tripadvisor.com/media/photo-s/1c/dd/6f/f3/hard-rock-cafe-punta.jpg',
            //         text: <div>
            //             <div>{info} The local branch of the famous café – serves drinks and food along with live
            //                 concerts and shows.
            //             </div>
            //             <div>{alert} If you are interested in booking an activity or need any assistance, you can
            //                 contact the production company throughout the event and we’ll be happy to help or do it for
            //                 you!
            //             </div>
            //         </div>
            //     },
            // ],
            wrappertabs: [
                {
                    name: "Joint Activities (June 4)",
                    keyToRender: 'tabs2',
                },
                {
                    name: "Individual Activities (Jun 5)",
                    keyToRender: 'tabs1',
                }
                // {
                //     name: "Cooking",
                //     keyToRender: 'tabs3',
                // },
                // {
                //     name: "Nightlife",
                //     keyToRender: 'tabs4',
                // }
            ],
        }
    }



    render() {
        const {wrappertabs} = this.state;
        return <div id="activities">
            <h1>Activities</h1>
            <div  className="content">
                <Tabs type="card">
                    {wrappertabs.map((tab, index) =>
                        <TabPane tab={tab.name} type="card" key={index}>
                            <Tabs type="card">
                            {this.state[tab.keyToRender].map((subtab, subindex) =>
                                <TabPane tab={subtab.name} className="tab-content" key={subindex}>
                                    <h2 className="tab-title">{subtab.name}</h2>
                                    <img src={subtab.img} className="tab-img"/>
                                    <div className="tab-text">{subtab.text}</div>
                                </TabPane>)
                            }
                            </Tabs>
                        </TabPane>
                    )}
                </Tabs>
            </div>
        </div>;
    }
}

export default Activities;
