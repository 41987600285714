export default {
    appName: 'Forter Vacation',
    appDescription: 'Visuzalize and organize large Forter trips',
    firebase: {
        apiKey: "AIzaSyB1sf3F3DpLiFfMyKRGppQCAQExzdIeIEc",
        authDomain: "forter-vacation.firebaseapp.com",
        databaseURL: "https://forter-vacation-default-rtdb.firebaseio.com",
        projectId: "forter-vacation",
        storageBucket: "forter-vacation.appspot.com",
        messagingSenderId: "754774998258",
        appId: "1:754774998258:web:6c6065a1761b73a9b2646b",
        measurementId: "G-E3KBPS7VSH"
    }
};
