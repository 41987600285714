import React from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {Parallax, Background} from "react-parallax";

const usaFlag = <img src="https://em-content.zobj.net/thumbs/120/apple/354/flag-dominican-republic_1f1e9-1f1f4.png"
                     style={{"width": '20px'}}/>

class Home extends React.Component {
    render() {
        return <div id="home">
            <Parallax bgImage="https://i.natgeofe.com/k/a6c9f195-de20-445d-9d36-745ef56042c5/OG_Colosseum_Ancient-Rome_KIDS_1122_3x2.jpg"
                      strength={300}>
                <div style={{height: 700}}>
                    <div className="insideStyle">
                        <h1>Forter Excellence Club 2024</h1>
                        <h2>Rome, Italy</h2>
                        <h2>June 3-6, 2024</h2>
                        <div id="register-wrapper"><Link id="register" to="/register">Register »</Link></div>
                        <div id="reminders">
                            <a href="https://chat.whatsapp.com/KcrvWxUrdicBQBEy5SlCD7" target="_blank" className="t-bottom t-sm" data-tooltip="Join The Whatsapp Group"><img src={require('../assets/logos/whatsapp.png')} /> </a>
                            {/*<a href="https://calendar.google.com/calendar/u/0?cid=Y18yZzJvaG0wZzdvMjNnZ21jaGYzZW0zb3ZjY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t" target="_blank" className="t-bottom t-sm" data-tooltip="Add to your Google Calendar"><img src={require('../assets/logos/gcal.png')} /> </a>*/}
                        </div>
                    </div>
                </div>
            </Parallax>

            <div className="content">
                <div>Congratulations! In appreciation of your achievements, we are thrilled to invite you and a guest to the Forter Excellence Club to be held in the amazing Rome, Italy.
                    </div><div>
                    This award recognizes your dedication, performance, and success over the past year.
                    </div><div>
                We have an exciting agenda planned for you and ask that you take a few minutes to read and complete the registration form by <b>17th April</b>. This event site is your resource for all event, travel and activity information.
                    </div><div>
                    Looking forward to an unforgettable experience!
                </div>
            </div>
        </div>;
    }
}

export default Home;
