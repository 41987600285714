import React from "react";
import {Collapse} from 'antd';

const {Panel} = Collapse;

const QA = [
    // {
    //     "question": "Can I bring a guest?",
    //     "answer": <div className="answer"><div>Yes. Every winner may bring 1 guest so long as the guest is above the age of 21 and not a former or current employee of Forter.</div></div>
    // },
    // {
    //     "question": "Can I bring other guests that I pay for?",
    //     "answer": <div className="answer"><div>No. Our rooms are only for 2 adults maximum. Additional guests (including children), regardless of cost or who pays, are prohibited.</div></div>
    // },
    // {
    //     "question": "What if I’m invited and cannot attend?",
    //     "answer": <div className="answer"><div>Please be sure to RSVP “No”. There is no alternative prize, but SWAG will be mailed after the event to those who can’t attend.</div></div>
    // },
    {
        "question": "Should I put in PTO for the Excellence Club?",
        "answer": <div className="answer"><div>No.</div></div>
    },
    // {
    //     "question": "Am I allowed to travel from another location besides my allotted home office?",
    //     "answer": <div className="answer"><div>You would need to verify that the cost of your roundtrip ticket from your home city would be comparable to (or less than) your proposed deviation.For example, if you are based in Paris but want to come from California, the cost of your flight from California/Rome/Paris needs to be equal to or less than a roundtrip flight from Paris/Rome/Paris. If it’s not, you will be responsible for your arrival to Excellence Club and Forter would only cover the return flight home. It is not permitted to charge the more expensive flights to Forter and attempt to repay the company.</div></div>
    // },
    {
        "question": "Can I come earlier and/or stay longer?",
        "answer": <div className="answer"><div>Sure! However, you must note 1) All hotel nights outside of the event are at your own expense. 2) Forter will not reimburse expenses accrued before or after the Excellence Club dates.</div></div>
    },
    // {
    //     "question": "I want to explore other cities before or after the Excellence Club. May I?",
    //     "answer": <div className="answer"><div>Forter will cover 1 round-trip flight. If you are seeking to stop somewhere before or after Rome, the flights will become your own expense. You may not use the invitation to book multiple stops for other cities.</div></div>
    // },
    {
        "question": "What is included while we are in Rome?",
        "answer": <div className="answer">    <div>Forter will cover hotel rooms and flights and arrange activities for each attendee and guest. The only planned expense is using taxis from the airport to the hotel and from the hotel to the airport, for which you will be able to reimburse. You will be expected to cover the costs of any activities you choose to do during your free time.  Forter will cover dinner on Monday, breakfast/lunch/dinner on Tuesday and Wednesday and breakfast on Thursday. Additionally, you will be able to expense meals on each of your travel days in accordance with our normal expense policy. (ie. Sunday/Monday and Thursday) For expense guidelines outside of meals, please refer to the Forter employee expense policy, which is also included in the Forter travel policy.</div></div>
    },
    {
        "question": "What is the Agenda?",
        "answer": <div className="answer">    <div>You can find the entire schedule by navigating to the 'Agenda' tab.</div></div>
    },
    {
        "question": "Is there a dress code?",
        "answer": <div className="answer">    <div>If a dress code is required for a particular event or occasion, we will include it in the agenda.</div></div>
    },
    {
        "question": "I have other questions about travel and policy.",
        "answer": <div className="answer">    <div>Please reach out to excellenceclub@forter.com</div></div>
    },
    {
        "question": "Arrivals and Event Check-In",
        "answer": <div className="answer">    <div>To get to the hotel from the airport, you must order a local taxi and keep the receipt for reimbursement. Hotel check-in is at 3:00 PM and we will do our best to accommodate early check-ins based on availability.</div></div>
    },
    {
        "question": "Departures & Hotel Checkout",
        "answer": <div className="answer">    <div>Hotel checkout is at noon and we will do our best to accommodate late checkouts as needed. You will be able to continue to use the hotel facilities and store your baggage if your departure time is after checkout.</div></div>
    },
    {
        "question": "Are there mandatory Activities?",
        "answer": <div className="answer">    <div>All the optional and mandatory events have been listed in the agenda.</div></div>
    },
    {
        "question": "Will Forter reimburse me for a communication / Wi-Fi package?",
        "answer": <div className="answer">    <div>No. Wi-Fi will be available in the hotel. If there are any specific work-related requirements for a communication package, please contact excellenceclub@forter.com.</div></div>
    },
    {
        "question": "What is the weather like in Rome?",
        "answer": <div className="answer">    <div>In June, Rome experiences warm and sunny weather with average highs between 26 to 32 degrees Celsius (79 to 90 degrees Fahrenheit). It's advisable to wear light clothing, use sunscreen, and stay hydrated during this peak summer month.</div></div>
    }
]

class Faq extends React.Component {
    render() {
        return <div id="faq">
            <h1>FAQ</h1>
            <div className="content">
                <Collapse className="faq-wrapper">
                    {QA.map((qa, index) => {
                            return <Panel header={qa.question} key={index}>
                                {qa.answer}
                            </Panel>
                        }
                    )}
                </Collapse>
            </div>
        </div>;
    }
}

export default Faq;
