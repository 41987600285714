import React  from "react";
import './App.css';

import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


// pages
import Home from './pages/home-page';
import Agenda from './pages/agenda-page';
import Destination from './pages/destination-page';
import Activities from './pages/activities-page';
import FAQ from './pages/faq-page';
import Register from './pages/register-page';
import Packing from "./pages/packing-page";
import Success from "./pages/success-page";

// auth
import config from './config';
import firebase from 'firebase/app';
import 'firebase/auth';

export const app = firebase.initializeApp(config.firebase);

const ROUTES = [
  { path: '/', name: 'Home', component: Home, exact: true },
  { path: '/agenda', name: 'Agenda', component: Agenda },
  { path: '/destination', name: 'Destination', component: Destination },
  { path: '/activities', name: 'Activities', component: Activities },
  { path: '/packing', name: 'Packing List', component: Packing },
  { path: '/faq', name: 'FAQ', component: FAQ },
  { path: '/register', name: 'Register', component: Register },
  { path: '/success', name: '', component: Success, hideFromSideMenu: true },
];



class App extends React.Component {

  async authenticateToFirebaseViaGoogleSSO() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.setCustomParameters({
      login_hint: '*@forter.com'
    });

    await app.auth().signInWithRedirect(provider);
  }

  isLoggedIn(){
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async authenticate() {
    const logged = await this.isLoggedIn();
    if (logged) {
      return;
    }
    await this.authenticateToFirebaseViaGoogleSSO();
  }

  async componentDidMount() {
    await this.authenticate();
  }

  render(){
    return (
      <Router>
        <div id="app">
          <div id="navigation">
            <Link to="/"><img id="forter-logo" src="https://i.imgur.com/DsQJz36.png" /></Link>
            {ROUTES.filter(i => !i?.hideFromSideMenu).map((route, index) => (
              <div key={index}>
                <Link to={route.path}>{route.name}</Link>
              </div>
            ))}
          </div>
          <Switch>
            {ROUTES.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} >
                <route.component id="router" />
                </Route>
            ))}
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
